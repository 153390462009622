import React from 'react';

// import { useQuery } from '@apollo/client';
// import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
// import { GET_SIMILAR_PRODUCTS } from '../../queries';
import { IProduct } from '../../types';
import {
  contentSquereCustomTag,
  artificialPageview,
  contentSquarePageEvent,
  dynataAddToCart,
  normalizeNameForUrl,
  cutOffMessage,
  setWindowVars,
} from '../../helpers';

export const useCartHook = () => {
  const {
    dispatch,
    value: { cart },
  } = useAppContext();
  // const { storeId, harvestId } = useParams();
  const [totalCartValue, setTotalCartValue] = React.useState(0);
  const [totalItemsInCart, setTotalItemsInCart] = React.useState(0);
  const [checkoutMessage, setCheckoutMassege] = React.useState('');

  let href = window.location.pathname.replace('cart', '');

  const handleCart = (type: string, product: IProduct) => {
    contentSquarePageEvent(type);
    if (type === 'add') {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: {
          id: product.id,
          name: product.Name,
          regularPrice: product.RegularPrice,
          price: product.Price,
          source: 'cart',
          href: href,
          promo: product.URLPromoCode,
          brand: product.Brand,
        },
      });
    }
    if (type === 'remove') {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: {
          product: {
            id: product.id,
            name: product.Name,
            regularPrice: product.RegularPrice,
            price: product.Price,
            source: 'cart',
            href: href,
            promo: product.URLPromoCode,
            brand: product.Brand,
          },
          type: 'REMOVE_ONE',
        },
      });
    }
    if (type === 'removeAll') {
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: {
          product: {
            id: product.id,
            name: product.Name,
            regularPrice: product.RegularPrice,
            price: product.Price,
            source: 'cart',
            href: href,
            promo: product.URLPromoCode,
            brand: product.Brand,
          },
          type: 'REMOVE_ALL',
        },
      });
    }
  };

  const checkout = (type: string, productList: [IProduct]) => {
    // dynataAddToCart(href + 'cart/checkout');
    setWindowVars();
    cutOffMessage();
    const products = productList.map((product: IProduct) => {
      let promoCode;
      product.URLPromoCode != null
        ? (promoCode = '__promoCode-' + product.URLPromoCode)
        : (promoCode = '__promoCode-');
      let price = product.Price ? product.Price : product.RegularPrice;
      dynataAddToCart(
        href +
          'ips/' +
          product.id +
          '__' +
          product.Brand +
          '__' +
          normalizeNameForUrl(product.Name) +
          '_' +
          price +
          promoCode +
          '_cartadd' +
          product.amount
      );
      dispatch({
        type: actions.CHECKOUT,
        payload: {
          product: {
            id: product.id,
            name: product.Name,
            regularPrice: product.RegularPrice,
            price: product.price,
            source: 'cart',
            href: href,
          },
          type: 'CHECKOUT',
        },
      });
    });
    contentSquarePageEvent(type);
    setCheckoutMassege('checkoutMessage');
  };

  const checkoutEvent = (type: string, productList: [IProduct]) => {
    // dynataAddToCart(href + 'cart/checkout');
    setWindowVars();
    if (productList.length > 0) {
      cutOffMessage();
      const products = productList.map((product: IProduct) => {
        let promoCode;
        product.URLPromoCode != null
          ? (promoCode = '__promoCode-' + product.URLPromoCode)
          : (promoCode = '__promoCode-');
        let price = product.Price ? product.Price : product.RegularPrice;
        dynataAddToCart(
          href +
            'ips/' +
            product.id +
            '__' +
            product.Brand +
            '__' +
            normalizeNameForUrl(product.Name) +
            '_' +
            price +
            promoCode +
            '_cartadd' +
            product.amount
        );
        dispatch({
          type: actions.CHECKOUT,
          payload: {
            product: {
              id: product.id,
              name: product.Name,
              regularPrice: product.RegularPrice,
              price: product.price,
              source: 'cart',
              href: href,
            },
            type: 'CHECKOUT',
          },
        });
      });
      contentSquarePageEvent(type);
      setCheckoutMassege('checkoutMessage');
    }
  };

  React.useEffect(() => {
    const totalValue = cart
      .reduce((prev: any, current: any) => {
        const price = current.Price ? current.Price : current.RegularPrice;
        let totalAmount;
        if (current.PromoAmount && current.amount >= current.PromoAmount) {
          totalAmount =
            Math.floor(current.amount / current.PromoAmount) *
              current.PromoCount *
              price +
            (current.amount % current.PromoAmount) * price;
        } else {
          totalAmount = current.amount * price;
        }
        return totalAmount + prev;
      }, 0)
      .toFixed(2);
    setTotalCartValue(totalValue);

    const totalNumberItems = cart.reduce(
      (prev: any, current: any) => prev + current.amount,
      0
    );
    setTotalItemsInCart(totalNumberItems);
  }, [cart]);

  // const { data: similiarData, loading: similarLoading } = useQuery(
  //   GET_SIMILAR_PRODUCTS,
  //   {
  //     variables: {
  //       fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
  //     },
  //     skip: !totalItemsInCart || totalItemsInCart === 0,
  //   }
  // );

  // const [shuffledProducts, setShuffledproducts] = React.useState({
  //   shuffledSimilar: [],
  //   shuffledRecommended: [],
  // });

  // React.useEffect(() => {
  //   if (!similarLoading) {
  //     const products = !similarLoading && similiarData?.getHarvest?.children;

  //     const handleShuffling = (items: any) =>
  //       items
  //         ?.map((value: any) => ({ value, sort: Math.random() }))
  //         .sort((a: any, b: any) => a.sort - b.sort)
  //         .map(({ value }: any) => value)
  //         ?.filter((product: any) => product?.Name);

  //     const shuffledSimilar = handleShuffling(products)?.slice(0, 10);
  //     const shuffledRecommended = handleShuffling(products)?.slice(0, 10);

  //     setShuffledproducts({ shuffledSimilar, shuffledRecommended });
  //   }
  // }, [similarLoading, similiarData]);

  React.useEffect(() => {
    contentSquereCustomTag(
      1,
      'Path',
      window.location.pathname + window.location.hash.replace('#', '?__'),
      ''
    );
    contentSquereCustomTag(2, 'pageType', 'Cart', '');
    artificialPageview(
      window.location.pathname + window.location.hash.replace('#', '?__')
    );
  }, []);

  React.useEffect(() => {
    if (window.finishIt == true) {
      checkoutEvent('checkout', cart);
      setWindowVars();
    }
  }, [window.finishIt]);

  return {
    // similarLoading,
    handleCart,
    checkout,
    totalCartValue,
    totalItemsInCart,
    // shuffledProducts: shuffledProducts.shuffledSimilar,
    checkoutMessage,
  };
};
