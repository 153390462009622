import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_SIMILAR_PRODUCTS, LIST_ALL_TERMS, GET_HOME_PAGE_BY_ID } from '../../queries';
import { contentSquereCustomTag, artificialPageview } from '../../helpers';
import { useConfiguration } from '../../hooks/useConfiguration';


export const useMainRoute = () => {

  const { storeId = '', harvestId = ''} = useParams();

  const {
    HomePageTemplate
  } = useConfiguration( { storeId, harvestId });

  const { data, loading } = useQuery(LIST_ALL_TERMS);

  // const { data: similiarData, loading: similarLoading } = useQuery(
  //   GET_SIMILAR_PRODUCTS,
  //   {
  //     variables: {
  //       fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
  //     },
  //   }
  // );

  let fullpath;

  HomePageTemplate == undefined || HomePageTemplate?.fullpath == '' ? fullpath = '/Home Pages/_default' : fullpath = HomePageTemplate?.fullpath;

  const { data: homePageStructure, loading: homePageLoading } = useQuery(GET_HOME_PAGE_BY_ID,
    {
      variables: {
        fullpath: fullpath,
      },
    }
  )

  const [categories, setCategories] = React.useState();

  const [homePage, setHomePage] = React.useState();

  // const [shuffledProducts, setShuffledproducts] = React.useState({
  //   shuffledSimilar: [],
  //   shuffledRecommended: [],
  // });

  React.useEffect(() => {
    if (!homePageLoading) {
      const edges = homePageStructure.getHomePage;

      const homePageElements = edges;

      setHomePage(edges);
    }
  }, [homePageLoading, homePageStructure, harvestId, storeId]);

  React.useEffect(() => {
    if (!loading) {
      const edges = data?.getEPogTermListing?.edges;

      const categories = edges
        ?.filter(
          (edge: any) =>
            edge?.node?.fullpath?.includes(storeId) &&
            edge?.node?.fullpath?.includes(harvestId) &&
            edge?.node?.fullpath?.includes('Category')
        )
        .map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: any, b: any) => a.sort - b.sort)
        .map(({ value }: any) => value);
      setCategories(categories);
    }
  }, [loading, data, harvestId, storeId]);

  // React.useEffect(() => {
  //   if (!similarLoading) {
  //     const products = !similarLoading && similiarData?.getHarvest?.children;

  //     const handleShuffling = (items: any) =>
  //       items
  //         ?.map((value: any) => ({ value, sort: Math.random() }))
  //         ?.sort((a: any, b: any) => a.sort - b.sort)
  //         ?.map(({ value }: any) => value)
  //         ?.filter((product: any) => product?.Name);

  //     const shuffledSimilar = handleShuffling(products)?.slice(0, 10);
  //     const shuffledRecommended = handleShuffling(products)?.slice(0, 10);

  //     setShuffledproducts({ shuffledSimilar, shuffledRecommended });
  //   }
  // }, [similarLoading, similiarData]);

  React.useEffect(() => {
    contentSquereCustomTag(
      1,
      'Path',
      window.location.pathname + window.location.hash.replace('#', '?__'),
      ''
    );
    contentSquereCustomTag(2, 'pageType', 'HomePage', '');
    artificialPageview(
      window.location.pathname + window.location.hash.replace('#', '?__')
    );
  }, []);

  return {
    loading,
    // similarLoading,
    // shuffledProducts,
    categories,
    homePage
  };
};
