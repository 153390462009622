import { imgSrcByEnv } from '../../../../../config/imgSrcByEnv';
import { theme } from '../../../../theme';
import { useNavigate, useParams } from 'react-router-dom';
import { IProduct } from '../../../../types';
import styled from '@emotion/styled';
import { useConfiguration } from '../../../../hooks/useConfiguration';
import { normalizeNameForUrl } from '../../../../helpers';

export const CardDetailsSection = ({ product }: { product: IProduct }) => {
  const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
    product;
  const urlPrice = Price !== 0 ? Price : RegularPrice;
  const brand = Brand ? Brand : '';
  let promoCode: string;
  if (URLPromoCode != null) {
    promoCode = '__promoCode-' + URLPromoCode;
  } else {
    promoCode = '__promoCode-';
  }
  const navigate = useNavigate();
  const navigateToProduct = (id: string) =>
    navigate(`/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
      Name
    )}_${urlPrice}_${promoCode}`);

  const { storeId = '', harvestId = '' } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency ? Currency : '$';
  const price =
    product?.Price !== 0
      ? product?.Price?.toFixed(2)
      : product?.RegularPrice?.toFixed(2);

  return (
    <CartShippingCard>
      <CardImageContainer>
        {product?.Gallery && product.Gallery.length > 0 && (
          <img
            src={`${imgSrcByEnv()}${product.Gallery[0]?.image?.fullpath}`}
            alt="product"
            onClick={() => navigateToProduct(product.id)}
          />
        )}
      </CardImageContainer>

      <CardProductDetailsContainer>
        <CardProductDetailsParagraph color={theme.target.green}>
          Get it by Wednesday, Jun 10
        </CardProductDetailsParagraph>
        <CardPriceContainer>
          {product.Price && product.Price !== 0 ? (
            <p className="price">
              {price}
              {currency}
              <span className="oldPrice">
                {' '}
                <s>
                  {product.RegularPrice.toFixed(2)}
                  {currency}
                </s>
              </span>
            </p>
          ) : (
            <p className="price">
              {price}
              {currency}
            </p>
          )}
        </CardPriceContainer>
        <CardProductDealsContainer>
          <CardProductDetailsParagraph
            color={theme.target.turquoise}
          ></CardProductDetailsParagraph>
        </CardProductDealsContainer>

        <CardProductDetailsParagraph>
          {product.Name.slice(0, 30)}
        </CardProductDetailsParagraph>

        {product?.Attribute &&
          product.Attribute.length > 0 &&
          product.Attribute.map((atr) => (
            <CardProductDetailsParagraph key={atr?.AttrName}>
              {atr.AttrName} {atr.AttrValue}
            </CardProductDetailsParagraph>
          ))}
      </CardProductDetailsContainer>
    </CartShippingCard>
  );
};

const CartShippingCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 10px 0px;
`;

const CardImageContainer = styled.div`
  width: 30%;

  img {
    height: 80px;
    width: 80px;
    border-radius: 8px;
    margin-left: 10px;
    object-fit: cover;
  }
`;

const CardProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;

  h1 {
    font-size: 24px;
    font-weight: 700;
  }
`;

const CardProductDetailsParagraph = styled.div<{ color?: string }>`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: ${({ color }) => color};

  span {
    font-size: 12px;
  }
`;

const CardProductDealsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const CardPriceContainer = styled.div`
  font-size: 22px;
  font-weight: 700;
  .oldPrice {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.target.greyDark};
  }
`;
